<template>
  <div class="flex flex-col justify-center items-center pt-24 pb-12 md:pt-12">
    <h1 class="title">
      My work
    </h1>
    <hr class="bg-lightBlue-500 h-1 border-none mt-1 w-24 md:w-32 lg:w-40" />
    <ProjectCard :project="projects.blog">
      <p class="text mt-4 self-start">
        Blog application where users can create and publish their posts and
        other people can read and leave comments. App was created as part of the
        <a
          href="https://www.theodinproject.com"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500"
        >
          The Odin Project's
        </a>
        full stack JavaScript curriculum.
      </p>
      <p class="text mt-4 self-start">
        Backend was developed using Node and Express, and the frontend was
        developed with Vue and custom CSS. It uses JSON Web Token for
        authentication and MongoDB for the database.
      </p>
    </ProjectCard>
    <ProjectCard :project="projects.paycheck">
      <p class="text mt-4 self-start">
        Application to keep track of days worked for the current paychek, users
        can create, view, and delete paychecks and workdays. App was created to
        solve a personal problem of always having to guess and misremember how
        many hours and money in tips I had made for my paycheck. The app
        generates a summary of the latest paycheck on the home page.
      </p>
      <p class="text mt-4 self-start">
        Backend was developed using Node and Express, and the frontend was
        developed with Vue and custom CSS for responsive design. It uses JSON
        Web Token for authentication and MongoDB for the database.
      </p>
    </ProjectCard>
    <ProjectCard :project="projects.cv">
      <p class="text mt-4 self-start">
        CV Maker application where users can input information to create a
        resume. A preview is created using
        <a
          href="https://react-pdf.org/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500"
        >
          React-pdf library,
        </a>
        and can be downloaded as a pdf file. App was created as part of the
        <a
          href="https://www.theodinproject.com"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500"
        >
          The Odin Project's
        </a>
        React section, but it is not optimized for tablet or mobile.
      </p>
    </ProjectCard>
    <ProjectCard :project="projects.pokeacard">
      <p class="text mt-4 self-start">
        Poke-A-Card is a memory card game where the user must choose the pokemon
        they haven't chosen. The game keeps track of the user's score and the
        highest score. App was created as part of the
        <a
          href="https://www.theodinproject.com"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500"
        >
          The Odin Project's
        </a>
        React section, and it is optimized for mobile.
      </p>
      <p class="text mt-4 self-start"></p>
    </ProjectCard>
  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import projects from '@/utils/projects.js'

export default {
  name: 'MyWork',
  components: { ProjectCard },
  data() {
    return {
      projects
    }
  }
}
</script>
