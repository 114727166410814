<template>
  <div class="w-10/12 max-w-2xl flex flex-col items-center">
    <h2 class="sub-title mt-4 text-center">{{ project.title }}</h2>
    <img
      :src="project.picture.link"
      :alt="project.picture.alt"
      class="mt-4 w-10/12 md:w-8/12"
    />
    <slot />
    <ul class="self-start mt-4">
      <li v-for="(link, idx) in project.links" :key="link.text + idx">
        <a
          :href="link.url"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500  tracking-wide leading-relaxed text-sm md:text-base"
        >
          {{ link.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard.vue',
  props: ['project']
}
</script>
