export default {
  blog: {
    title: 'Blog App',
    picture: {
      link: 'https://i.imgur.com/PKazeO1.png',
      alt: 'Blog App Picture'
    },
    links: [
      {
        url: 'https://immense-scrubland-37414.herokuapp.com/',
        text: 'Blog-Reader App'
      },
      {
        url: 'https://bagged-keener-22540.herokuapp.com/',
        text: 'Blog-Admin App'
      }
    ]
  },
  paycheck: {
    title: 'Paycheck-Tracker',
    picture: {
      link: 'https://i.imgur.com/O8lYUEx.png',
      alt: 'Paycheck Tracker Picture'
    },
    links: [
      {
        url: 'https://paycheck-tracker.herokuapp.com/',
        text: 'Paycheck-Tracker App'
      }
    ]
  },
  cv: {
    title: 'CV Maker',
    picture: {
      link: 'https://i.imgur.com/uFw8A4Y.png',
      alt: 'CV Maker'
    },
    links: [
      {
        url: 'https://danydlo.github.io/cv-project',
        text: 'CV Maker App'
      }
    ]
  },
  pokeacard: {
    title: 'Poke-A-Card',
    picture: {
      link: 'https://i.imgur.com/pygAXhT.png',
      alt: 'Poke A Card'
    },
    links: [
      {
        url: 'https://danydlo.github.io/memory-card',
        text: 'Poke-A-Card'
      }
    ]
  }
}
